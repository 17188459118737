import * as React from "react";
import { UitkUnformattedHtml, UitkUnformattedHtmlReplaceDefaults } from "@egds/react-core/unformatted-html";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { UitkList, UitkListItem } from "@egds/react-core/list";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkTable, UitkTableRow, UitkTableCell, UitkTableCellBorder } from "@egds/react-core/tables";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { LazyLoad } from "components/utility/LazyLoad/LazyLoad";

type DecorateEditorialHtmlProps = { text: string | undefined };

export const DecorateEditorialHtml: React.FC<DecorateEditorialHtmlProps> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <UitkUnformattedHtml
      elementsToReplace={{
        ...UitkUnformattedHtmlReplaceDefaults,
        h1: ({ children, ...props }) => (
          <UitkHeading tag="h1" {...props} size={4}>
            {children}
          </UitkHeading>
        ),
        h2: ({ children, ...props }) => (
          <UitkHeading tag="h2" {...props} size={4}>
            {children}
          </UitkHeading>
        ),
        h3: ({ children, ...props }) => (
          <UitkHeading tag="h3" {...props} size={6}>
            {children}
          </UitkHeading>
        ),
        h4: ({ children, ...props }) => (
          <UitkHeading tag="h4" {...props} size={6}>
            {children}
          </UitkHeading>
        ),
        h5: ({ children, ...props }) => (
          <UitkHeading tag="h5" {...props} size={5}>
            {children}
          </UitkHeading>
        ),
        h6: ({ children, ...props }) => (
          <UitkHeading tag="h6" {...props} size={5}>
            {children}
          </UitkHeading>
        ),
        li: ({ children, ...props }) => <UitkListItem {...props}>{children}</UitkListItem>,
        ul: ({ children, ...props }) => (
          <UitkSpacing margin={{ block: "two" }}>
            <UitkList {...props}>{children}</UitkList>
          </UitkSpacing>
        ),
        ol: ({ children, ...props }) => (
          <UitkSpacing margin={{ block: "two" }}>
            <UitkList {...props} type="ordered">
              {children}
            </UitkList>
          </UitkSpacing>
        ),
        p: ({ children, ...props }) => <p {...props}>{children}</p>,
        a: ({ children, ...props }) => <a {...props}>{children}</a>,
        table: ({ children, ...props }) => (
          <UitkTable border striped="odd" {...props}>
            <>{children}</>
          </UitkTable>
        ),
        tr: ({ children, ...props }) => (
          <UitkTableRow {...props}>
            <>{children}</>
          </UitkTableRow>
        ),
        td: ({ children, ...props }) => (
          <UitkTableCell border={[UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]} {...props}>
            {children}
          </UitkTableCell>
        ),
        th: ({ children, ...props }) => (
          <UitkTableCell border={[UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]} tag="th" {...props}>
            <UitkText whiteSpace="normal">{children}</UitkText>
          </UitkTableCell>
        ),
      }}
      htmlToParse={text}
      plainTextElement={({ children }) => <div>{children}</div>}
    />
  );
};

// extracting the citiCode, if present in the current path and returning it and if not then, returning empty string.
export const returnCitiCode = (searchUrl: string) => {
  const INITIAL_INDEX = searchUrl.indexOf("citiCode") + 9; //to get citiCode value's beginning index in the searchUrl
  const FINAL_INDEX = searchUrl.indexOf("citiCode") + 12; //to get citiCode value's final index in the serachUrl
  return searchUrl.includes("citiCode") ? searchUrl.substring(INITIAL_INDEX, FINAL_INDEX) : "";
};

type EditorialShouldLazyLoad = {
  isAboveTheFold?: boolean;
  context?: ExtendedContextStore;
  children: React.ReactElement;
};

export const ConditionalLazyLoad = ({ isAboveTheFold, context, children }: EditorialShouldLazyLoad) =>
  isAboveTheFold ? <>{children}</> : <LazyLoad context={context}>{children}</LazyLoad>;

import * as React from "react";
import {
  UitkLayoutGrid,
  UitkLayoutGridItem,
  UitkLayoutGridColumnRow,
  UitkLayoutGridSpan,
} from "@egds/react-core/layout-grid";
import { UitkText } from "@egds/react-core/text";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { UitkSpacing } from "@egds/react-core/spacing";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";

export const CitiTripleDipIconSectionWithAdd = (props: { items: EditorialModel[] }) => {
  const { items } = props;
  if (items.length !== 3) {
    return null;
  }

  const IconColSpan = {
    small: 1 as UitkLayoutGridSpan,
    medium: 1 as UitkLayoutGridSpan,
    large: 5 as UitkLayoutGridSpan,
    extra_large: 5 as UitkLayoutGridSpan,
  };

  const gridColumns = {
    extra_large: 6 as UitkLayoutGridColumnRow,
    large: 6 as UitkLayoutGridColumnRow,
    medium: 1 as UitkLayoutGridColumnRow,
    small: 1 as UitkLayoutGridColumnRow,
  };

  return (
    <UitkLayoutGrid columns={gridColumns} space="four">
      <UitkLayoutGridItem colSpan={IconColSpan}>
        <UitkSpacing padding={{ inlinestart: "three" }}>
          <UitkLayoutGrid>
            <div>
              <UitkText align="center">
                <UitkSpacing padding={{ blockend: "three" }}>
                  <DecorateEditorialHtml text={items[0].text} />
                </UitkSpacing>
              </UitkText>
              <UitkText align="center" size={500} weight="bold">
                <UitkSpacing padding={{ block: "half" }}>
                  <DecorateEditorialHtml text={items[1].text} />
                </UitkSpacing>
              </UitkText>
              <UitkText align="center" size={300}>
                <UitkSpacing padding={{ block: "half" }}>
                  <DecorateEditorialHtml text={items[2].text} />
                </UitkSpacing>
              </UitkText>
            </div>
          </UitkLayoutGrid>
        </UitkSpacing>
      </UitkLayoutGridItem>
      <UitkLayoutGridItem colSpan={1}>
        <div>
          <UitkSpacing padding={{ blockstart: "four", inlinestart: "two" }}>
            <UitkText align="center">
              <UitkSpacing padding={{ blockend: "six" }}>
                <UitkIcon name="add" size={UitkIconSize.LARGE} />
              </UitkSpacing>
            </UitkText>
          </UitkSpacing>
        </div>
      </UitkLayoutGridItem>
    </UitkLayoutGrid>
  );
};
export default CitiTripleDipIconSectionWithAdd;

import * as React from "react";
import EditorialBase from "components/flexComponents/Editorial/factory/EditorialBase";
import { UitkCard } from "@egds/react-core/cards";
import { UitkText, UitkHeading, UitkSubheading } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { ContentProps, EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { UitkPrimaryButton } from "@egds/react-core/button";
import { FlexClickTracker } from "components/utility/analytics/FlexClickTracker/FlexClickTracker";
import MediaDisplay from "../items/MediaDisplay";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";

export class HalfMediaEditorial extends EditorialBase {
  public constructor(editorial: EditorialFactoryProps) {
    super(editorial);
    this.linkName = "tab1carouseltextlink";
  }

  /**
   * An Editorial consists of HTML strings that we parse through with the best of
   * our abilities and display as is on the page. Mainly, we do not control the
   * content of an Editorial, we simply put them in the page.
   */
  public generate(props: ContentProps) {
    const rfrrid = this.getRfrrid();
    const { items, cardLinkLabel, cardLinkUrl, title: moduleTitle, subtitle: moduleSubtitle, boxType } = this.editorial;
    const { text, title, media, subtitle, id: itemId } = items[0];

    if (!media || media.length === 0) {
      return null;
    }

    const TrackedButton = FlexClickTracker(UitkPrimaryButton);

    return (
      <UitkSpacing padding={{ block: "three" }}>
        <div id={this.id} data-fm={this.fmId} data-fm-title-id={this.fmTitleId} className="HalfMediaEditorial">
          <UitkCard border={boxType !== "no-border"}>
            <div>
              <UitkLayoutGrid columns={{ small: 1, medium: 2 }} justifyContent="space-between">
                <UitkLayoutGridItem>
                  <MediaDisplay id={itemId} media={media} title={title} />
                </UitkLayoutGridItem>
                <UitkLayoutGridItem>
                  <div>
                    <UitkSpacing padding={{ block: "three", inline: "four" }}>
                      <div>
                        {moduleTitle && (
                          <UitkSpacing margin={{ blockend: "two" }}>
                            <div>
                              <UitkHeading tag="h2" size={5}>
                                {moduleTitle}
                              </UitkHeading>
                              {moduleSubtitle && <UitkSubheading>{moduleSubtitle}</UitkSubheading>}
                            </div>
                          </UitkSpacing>
                        )}
                        {title && (
                          <div>
                            <UitkHeading tag={moduleTitle ? "h3" : "h2"} size={5}>
                              {title}
                            </UitkHeading>
                            {subtitle && <UitkSubheading>{subtitle}</UitkSubheading>}
                          </div>
                        )}
                        <UitkSpacing margin={{ blockstart: "two" }}>
                          <UitkText size={300}>
                            <div onClick={this.trackRfrridClick(useClickTracker())}>
                              <DecorateEditorialHtml text={text} />
                            </div>
                          </UitkText>
                        </UitkSpacing>
                        {cardLinkUrl && cardLinkLabel && (
                          <UitkSpacing margin={{ blockstart: "four" }}>
                            <TrackedButton
                              moduleName="HalfMediaEditorial"
                              href={cardLinkUrl}
                              rfrr={rfrrid}
                              aria-label={cardLinkLabel}
                            >
                              {cardLinkLabel}
                            </TrackedButton>
                          </UitkSpacing>
                        )}
                      </div>
                    </UitkSpacing>
                  </div>
                </UitkLayoutGridItem>
              </UitkLayoutGrid>
            </div>
          </UitkCard>
        </div>
      </UitkSpacing>
    );
  }
}

export default HalfMediaEditorial;

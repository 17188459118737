import { EditorialFactoryProps } from "components/flexComponents/Editorial/typings";
import * as React from "react";
import { UitkHeading, UitkSubheading } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";
import RenderAbleContentItemExpando from "components/flexComponents/Editorial/factory/impl/items/RenderAbleContentItemExpando";
import EditorialBase from "components/flexComponents/Editorial/factory/EditorialBase";

export class DestinationDescriptionEditorial extends EditorialBase {
  /* istanbul ignore next */
  public constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  public generate() {
    const classNameAttr = `${this.getEditorialItemClassName()}`;

    const {
      title: editorialTitle,
      subtitle: editorialSubtitle,
      text: editorialBody,
      id: editorialId,
    } = this.editorial.items[0];

    return (
      <div className={classNameAttr} id={this.id} data-fm={this.fmId} data-fm-title-id={this.fmTitleId}>
        <div className="EditorialModuleTitle">
          {editorialTitle && (
            <UitkSpacing margin={{ block: "one" }}>
              <UitkHeading tag="h2" size={2}>
                {editorialTitle}
              </UitkHeading>
            </UitkSpacing>
          )}
          {editorialSubtitle && (
            <UitkSpacing margin={{ block: "one" }}>
              <UitkSubheading tag="h3">{editorialSubtitle}</UitkSubheading>
            </UitkSpacing>
          )}
        </div>
        {editorialBody && (
          <UitkSpacing margin={{ block: "two" }}>
            <RenderAbleContentItemExpando id={editorialId} body={editorialBody} />
          </UitkSpacing>
        )}
      </div>
    );
  }
}

export default DestinationDescriptionEditorial;

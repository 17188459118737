import * as React from "react";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { UitkText } from "@egds/react-core/text";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { UitkSpacing } from "@egds/react-core/spacing";
import { ViewLarge, Viewport, ViewSmall } from "@shared-ui/viewport-context";

const CONTENT_ID_COUNT = 4;
export const CardComparisonImageSectionEditorial = (props: { items: EditorialModel[] }) => {
  const { items } = props;

  if (items.length !== CONTENT_ID_COUNT) {
    return null;
  }

  const [cardImage, cardImageSubText, cardTitle, cardImageSmall] = React.useMemo(
    () => items.map((item) => <DecorateEditorialHtml key={item.id} text={item.text} />),
    []
  );

  return (
    <div>
      <UitkSpacing padding={{ blockstart: "six" }}>
        <UitkText align="center">
          <Viewport>
            <ViewSmall>
              <div>{cardImageSmall}</div>
            </ViewSmall>
            <ViewLarge>
              <div>{cardImage}</div>
            </ViewLarge>
          </Viewport>
        </UitkText>
      </UitkSpacing>

      <UitkText align="center" size={200} weight="medium">
        <UitkSpacing padding={{ blockstart: "three" }}>
          <div>{cardImageSubText}</div>
        </UitkSpacing>
      </UitkText>
      <UitkText align="center" size={600} weight="bold">
        <UitkSpacing padding={{ blockstart: "four", blockend: "two" }}>
          <div>{cardTitle}</div>
        </UitkSpacing>
      </UitkText>
    </div>
  );
};

export default CardComparisonImageSectionEditorial;

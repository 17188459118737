import * as React from "react";
import { UitkText, UitkTextProps } from "@egds/react-core/text";
import { UitkCard, UitkCardContentSection } from "@egds/react-core/cards";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";

interface ConfidenceMsgGridItemProps {
  id: string;
  text: string;
  weight?: UitkTextProps["weight"];
  boxType?: string;
}

export const ConfidenceMsgGridItem: React.FC<ConfidenceMsgGridItemProps> = ({ id, text, weight, boxType }) => {
  const useUitkCard = boxType === "blossom-white";

  if (!text) {
    return null;
  }

  const mainContent = (
    <UitkText align="center" weight={weight} size={500}>
      <div data-fm-content={id}>
        <DecorateEditorialHtml text={text} />
      </div>
    </UitkText>
  );

  return useUitkCard ? (
    <UitkCard>
      <UitkCardContentSection>{mainContent}</UitkCardContentSection>
    </UitkCard>
  ) : (
    mainContent
  );
};

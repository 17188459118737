import * as React from "react";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { UitkText } from "@egds/react-core/text";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { UitkSpacing } from "@egds/react-core/spacing";

export const CitifaqHeader = (props: { items: EditorialModel[] }) => {
  const { items } = props;
  if (items.length !== 1) {
    return null;
  }

  return (
    <div>
      <UitkText align="center" size={600} weight="bold">
        <UitkSpacing padding={{ blockstart: "twelve", blockend: "six" }}>
          <DecorateEditorialHtml text={items[0].text} />
        </UitkSpacing>
      </UitkText>
    </div>
  );
};

export default CitifaqHeader;

import * as React from "react";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { UitkCard, UitkCardContentSection } from "@egds/react-core/cards";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText } from "@egds/react-core/text";

/**
 * Editorial item for a items with contentPurpose: Confidence message (and Highlights apparently)
 */
export const ConfidenceEditorialItem = ({
  id,
  text,
  uitkIconName,
  iconSize,
  boxType,
}: {
  id: string;
  text?: string;
  uitkIconName?: string;
  iconSize?: string;
  boxType?: string;
}) => {
  const confidenceUitkIconName = uitkIconName || "done";
  const useUitkCard = boxType === "blossom-white";

  const mainContent = (
    <UitkSpacing margin={{ blockstart: "two" }}>
      <UitkLayoutFlex
        alignItems="center"
        data-testid="confidenceMsg"
        data-fm-content={id}
        className="confidenceMessageItem"
      >
        <UitkIcon
          name={confidenceUitkIconName}
          size={iconSize === "large" ? UitkIconSize.LARGE : UitkIconSize.MEDIUM}
          data-testid="confidenceIcon"
          className={confidenceUitkIconName}
        />
        <UitkSpacing margin={{ inlinestart: "two" }}>
          <UitkText size={300}>
            <DecorateEditorialHtml text={text} />
          </UitkText>
        </UitkSpacing>
      </UitkLayoutFlex>
    </UitkSpacing>
  );

  return useUitkCard ? (
    <UitkCard>
      <UitkCardContentSection>{mainContent}</UitkCardContentSection>
    </UitkCard>
  ) : (
    mainContent
  );
};

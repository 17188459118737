import * as React from "react";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { UitkText } from "@egds/react-core/text";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { UitkSpacing } from "@egds/react-core/spacing";

export const CardComparisonBenefitsTitleEditorial = (props: { items: EditorialModel[] }) => {
  const { items } = props;
  const [benefitsTitle] = React.useMemo(
    () => items.map((item) => <DecorateEditorialHtml key={item.id} text={item.text} />),
    []
  );

  return (
    <UitkSpacing
      padding={{ medium: { inline: "twelve" }, large: { inline: "unset", inlinestart: "twelve" } }}
      margin={{ medium: { inline: "twelve" }, large: { inline: "unset", inlinestart: "six" } }}
    >
      <UitkText size={400} weight="bold">
        <UitkSpacing padding={{ blockend: "six" }}>
          <div>{benefitsTitle}</div>
        </UitkSpacing>
      </UitkText>
    </UitkSpacing>
  );
};

export default CardComparisonBenefitsTitleEditorial;

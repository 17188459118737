import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import * as React from "react";
import { UitkLayoutGrid, UitkLayoutGridColumnRow } from "@egds/react-core/layout-grid";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EditorialFlexModule } from "../../../../../../typings/microserviceModels/content-flex-module";
import { ConfidenceMsgGridItem } from "../items/ConfidenceMsgGridItem";

const gridColumns = {
  extra_large: 4 as UitkLayoutGridColumnRow,
  large: 4 as UitkLayoutGridColumnRow,
  medium: 2 as UitkLayoutGridColumnRow,
};

export const ConfidenceMsgGridEditorial = ({
  model,
  confidenceMsgClassName,
}: {
  model: EditorialFlexModule;
  confidenceMsgClassName: string;
}) => {
  const { items, boxType } = model;

  if (items.length !== 4) {
    return null;
  }

  const keyHelper = new ItemKeyHelper("editorial");

  const renderGridItems = () => {
    return items.map((item) => {
      if (!item.text) {
        return null;
      }

      const [icon, title, content] = item.text.split("<br />");

      return (
        <div key={item.id}>
          <UitkLayoutFlex direction="column" alignContent="center" blockSize="full_size">
            <UitkLayoutFlexItem key={keyHelper.next()} alignSelf="center">
              <div data-fm-content={item.id}>
                <ConfidenceMsgGridItem
                  id={model.id}
                  text={icon}
                  boxType={boxType}
                  weight={!content ? "bold" : undefined}
                />
              </div>
            </UitkLayoutFlexItem>
            <UitkLayoutFlexItem key={keyHelper.next()}>
              <div data-fm-content={item.id}>
                <ConfidenceMsgGridItem
                  id={model.id}
                  text={title}
                  boxType={boxType}
                  weight={content ? "bold" : "regular"}
                />
                <ConfidenceMsgGridItem id={model.id} text={content} boxType={boxType} weight="regular" />
              </div>
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
        </div>
      );
    });
  };

  return (
    <div data-testid="confidence-msg-grid-editorial">
      <UitkLayoutGrid columns={gridColumns} space="three" alignItems="center" className={confidenceMsgClassName}>
        {renderGridItems()}
      </UitkLayoutGrid>
    </div>
  );
};

import * as React from "react";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { UitkText } from "@egds/react-core/text";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { UitkSpacing } from "@egds/react-core/spacing";

const CONTENT_ID_COUNT = 3;
export const CardComparisonBenefitsSectionEditorial = (props: { items: EditorialModel[] }) => {
  const { items } = props;

  if (items.length !== CONTENT_ID_COUNT) {
    return null;
  }

  const [benefitsIcon, benefitsTitle, benefitsDescription] = React.useMemo(
    () => items.map((item) => <DecorateEditorialHtml key={item.id} text={item.text} />),
    []
  );

  return (
    <UitkSpacing
      padding={{ medium: { inline: "twelve" }, large: { inline: "unset", inlinestart: "twelve" } }}
      margin={{ medium: { inline: "twelve" }, large: { inline: "unset", inlinestart: "six" } }}
    >
      <UitkLayoutFlex space="three">
        <UitkLayoutFlexItem>
          <div>{benefitsIcon}</div>
        </UitkLayoutFlexItem>
        <UitkLayoutFlexItem>
          <div>
            <UitkText size={400} weight="bold">
              <div>{benefitsTitle}</div>
            </UitkText>
            <UitkText size={200}>
              <div>{benefitsDescription}</div>
            </UitkText>
          </div>
        </UitkLayoutFlexItem>
      </UitkLayoutFlex>
    </UitkSpacing>
  );
};

export default CardComparisonBenefitsSectionEditorial;

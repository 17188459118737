import * as React from "react";
import { ViewExtraLarge, ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { UitkUnformattedHtml } from "@egds/react-core/unformatted-html";

export const HeroImageEditorial = (props: { items: EditorialModel[] }) => {
  const { items } = props;
  if (items.length !== 3) {
    return null;
  }

  const [heroImageLarge, heroImageMedium, heroImageSmall] = React.useMemo(() => items.map((item) => item.text), []);

  return (
    <Viewport>
      <ViewExtraLarge>
        <UitkUnformattedHtml htmlToParse={heroImageLarge} />
      </ViewExtraLarge>
      <ViewMedium>
        <UitkUnformattedHtml htmlToParse={heroImageMedium} />
      </ViewMedium>
      <ViewSmall>
        <UitkUnformattedHtml htmlToParse={heroImageSmall} />
      </ViewSmall>
    </Viewport>
  );
};

export default HeroImageEditorial;

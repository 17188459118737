import * as React from "react";
import { UitkLayoutGrid } from "@egds/react-core/layout-grid";
import { UitkText } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { ViewLarge, Viewport, ViewSmall } from "@shared-ui/viewport-context";

export const CitiHeaderSection = (props: { items: EditorialModel[] }) => {
  const { items } = props;
  if (items.length !== 2) {
    return null;
  }

  return (
    <UitkLayoutGrid space="two">
      <Viewport>
        <ViewSmall>
          <UitkText size={600} align="center" weight="bold">
            <UitkSpacing padding={{ blockstart: "four" }}>
              <DecorateEditorialHtml text={items[0].text} />
            </UitkSpacing>
          </UitkText>
        </ViewSmall>
        <ViewLarge>
          <UitkText size={700} align="center" weight="bold">
            <UitkSpacing padding={{ blockstart: "four" }}>
              <DecorateEditorialHtml text={items[0].text} />
            </UitkSpacing>
          </UitkText>
        </ViewLarge>
      </Viewport>

      <UitkText align="center" size={300} weight="regular">
        <UitkSpacing padding={{ blockend: "six" }}>
          <DecorateEditorialHtml text={items[1].text} />
        </UitkSpacing>
      </UitkText>
    </UitkLayoutGrid>
  );
};
export default CitiHeaderSection;

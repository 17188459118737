import { UitkCardContentSection, UitkCard } from "@egds/react-core/cards";
import { UitkHeading, UitkSubheading, UitkText } from "@egds/react-core/text";
import { UitkFigure } from "@egds/react-core/images";
import { UitkIcon } from "@egds/react-core/icons";
import * as React from "react";
import { ConditionalLazyLoad, DecorateEditorialHtml } from "components/flexComponents/Editorial/ContentUtilities";
import { uitkFigureAspectRatio } from "../editorials/EditorialWithLink";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { useRenderableContext } from "./DefaultEditorial";
import { UitkSpacing, UitkSpacingProps } from "@egds/react-core/spacing";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";

export interface RenderableContentCardProps {
  shouldRenderBorder?: boolean;
  isExpandoEnabled?: boolean | "";
  toggleStatus: boolean;
  flipToggleStatus: () => void;
  displayEditorialClassNames: string;
}

export const RenderableContentCard = ({
  shouldRenderBorder,
  isExpandoEnabled,
  toggleStatus,
  flipToggleStatus,
  displayEditorialClassNames,
}: RenderableContentCardProps) => {
  const {
    context,
    boxType,
    key,
    media,
    imageAspectRatio = "16-9",
    padding,
    title,
    subtitle,
    text,
    view,
    trackRfrridClick,
    id,
    isAboveTheFold,
  } = useRenderableContext();

  const paddingTopValueContent = (title || subtitle) && !isExpandoEnabled ? "two" : "unset";

  const isBlossomLightGrey = boxType === "blossom-light-grey";
  const spacing: UitkSpacingProps = isBlossomLightGrey ? { padding: { inline: "twelve", block: "six" } } : {};
  const backgroundTheme = isBlossomLightGrey ? "secondary" : undefined;

  const expandoKeyboardPress = (e: React.KeyboardEvent) => {
    //button elements should allow click on enter or space when a keydown event occurs. Allow by default when a mouse event.
    if (e.key === "Enter" || e.key === " ") {
      flipToggleStatus();
    }
  };

  return (
    <ConditionalLazyLoad isAboveTheFold={isAboveTheFold} context={context}>
      <UitkCard
        backgroundTheme={backgroundTheme}
        className={boxType === "blossom-plain" ? "noBackground" : ""}
        border={shouldRenderBorder}
        key={key}
      >
        {media?.mediaUrl && (
          <UitkFigure ratio={uitkFigureAspectRatio(imageAspectRatio)}>
            <BlossomImage src={media.mediaUrl || ""} alt={media.mediaAlt || ""} />
          </UitkFigure>
        )}
        {isExpandoEnabled && (
          <div
            role="button"
            tabIndex={0}
            aria-expanded={!toggleStatus}
            className="header"
            onClick={flipToggleStatus}
            onKeyDown={expandoKeyboardPress}
          >
            <UitkCardContentSection padded={padding !== "none"} className={`${toggleStatus && "allBorderZero"}`}>
              <UitkLayoutFlex justifyContent="end">
                <UitkHeading tag="h2" size={4}>
                  {title}
                </UitkHeading>
                <UitkLayoutFlexItem>
                  <div>
                    <UitkSpacing margin={{ blockstart: "six" }}>
                      <UitkSubheading tag="h3">{subtitle}</UitkSubheading>
                    </UitkSpacing>
                  </div>
                </UitkLayoutFlexItem>
                <UitkLayoutFlexItem>
                  <UitkIcon name={`expand_${toggleStatus ? "more" : "less"}`} />
                </UitkLayoutFlexItem>
              </UitkLayoutFlex>
            </UitkCardContentSection>
          </div>
        )}
        <div
          data-testid="editorial-content"
          className={`editorialContent ${isExpandoEnabled && toggleStatus ? "is-visually-hidden" : ""}`}
        >
          <UitkCardContentSection padded={padding !== "none"}>
            <UitkSpacing {...spacing}>
              <UitkLayoutGrid>
                <UitkLayoutGridItem>
                  <div className={displayEditorialClassNames} data-fm-content={id} id={id}>
                    {title && !isExpandoEnabled && (
                      <UitkHeading tag="h2" size={4}>
                        {title}
                      </UitkHeading>
                    )}
                    {subtitle && !isExpandoEnabled && <UitkSubheading tag="h4">{subtitle}</UitkSubheading>}
                    {text && (
                      <UitkSpacing
                        padding={{ blockstart: paddingTopValueContent, blockend: "two", inlinestart: "one" }}
                      >
                        <UitkText size={300} weight="regular">
                          <div
                            onClick={trackRfrridClick && trackRfrridClick(useClickTracker())}
                            className={`${view === "single-column" ? "singleColumn" : "editorialTextColumn"} ${
                              isBlossomLightGrey ? "blossomLightGrey" : ""
                            }`}
                          >
                            <DecorateEditorialHtml text={text} />
                          </div>
                        </UitkText>
                      </UitkSpacing>
                    )}
                  </div>
                </UitkLayoutGridItem>
              </UitkLayoutGrid>
            </UitkSpacing>
          </UitkCardContentSection>
        </div>
      </UitkCard>
    </ConditionalLazyLoad>
  );
};

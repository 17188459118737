import * as React from "react";
import { UitkCard, UitkCardContentSection } from "@egds/react-core/cards";
import { UitkHeading, UitkText, UitkSubheading } from "@egds/react-core/text";
import { ContentProps, EditorialFactoryProps } from "../../../typings";
import EditorialBase from "../../EditorialBase";
import IsomorphicCarousel from "src/components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { ItemKeyHelper } from "src/components/utility/ItemKeyHelper";
import { UitkFigure, UitkFigureAspectRatioType } from "@egds/react-core/images";
import { useLocalization } from "@shared-ui/localization-context";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";

export class CarouselEditorial extends EditorialBase {
  constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  public generate(props: ContentProps) {
    const {
      templateComponent: {
        metadata: { id },
      },
    } = props;
    const itemsVisible = {
      lg: 3,
      md: 2,
      sm: 1,
    };
    const { formatText } = useLocalization();
    const keyHelper = new ItemKeyHelper(id);

    return (
      <div className="editorial-carousel" data-testid="carousel-editorial" id={id}>
        {this.editorial.title && (
          <UitkSpacing margin={{ block: "three", inline: "three" }}>
            <UitkHeading tag="h2" size={3}>
              {this.editorial.title}
            </UitkHeading>
          </UitkSpacing>
        )}
        <UitkSpacing margin={{ blockstart: "three", blockend: "unset", inlinestart: "three" }}>
          <IsomorphicCarousel
            itemsVisible={itemsVisible}
            buttonText={{
              nextButton: formatText("carousel.item.next"),
              prevButton: formatText("carousel.item.prev"),
            }}
            pageBy={1}
            peek
            wrapItems={false}
          >
            {this.editorial.items.map((editorialItem) => (
              <UitkCard className="blossom-plain" key={keyHelper.next()}>
                <div data-testid="editorial-carousel-content">
                  <UitkLayoutGrid columns={1}>
                    {editorialItem.media && (
                      <UitkLayoutGridItem>
                        <UitkFigure ratio={UitkFigureAspectRatioType.R16_9} className="all-image-fit-cover">
                          <BlossomImage
                            src={editorialItem.media[0].mediaUrl}
                            alt={editorialItem.media[0].mediaAlt || ""}
                          />
                        </UitkFigure>
                      </UitkLayoutGridItem>
                    )}
                    <UitkLayoutGridItem>
                      <UitkCardContentSection>
                        <div data-fm-content={editorialItem.id} id={editorialItem.id}>
                          {editorialItem.title && (
                            <UitkHeading tag="h3" size={6}>
                              {editorialItem.title}
                            </UitkHeading>
                          )}
                          {editorialItem.subtitle && <UitkSubheading>{editorialItem.subtitle}</UitkSubheading>}
                          {editorialItem.text && (
                            <UitkText>
                              <UitkSpacing padding={{ blockstart: "two" }}>
                                <div>
                                  <DecorateEditorialHtml text={editorialItem.text} />
                                </div>
                              </UitkSpacing>
                            </UitkText>
                          )}
                        </div>
                      </UitkCardContentSection>
                    </UitkLayoutGridItem>
                  </UitkLayoutGrid>
                </div>
              </UitkCard>
            ))}
          </IsomorphicCarousel>
        </UitkSpacing>
      </div>
    );
  }
}

import * as React from "react";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { UitkText } from "@egds/react-core/text";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { UitkSpacing } from "@egds/react-core/spacing";

const CONTENT_ID_COUNT = 6;
export const CardComparisonFeatureSectionEditorial = (props: { items: EditorialModel[] }) => {
  const { items } = props;

  if (items.length !== CONTENT_ID_COUNT) {
    return null;
  }

  const [
    cardStatusFeatureOneIcon,
    cardStatusFeatureOneDescription,
    cardStatusFeatureTwoIcon,
    cardStatusFeatureTwoDescription,
    cardStatusFeatureThreeIcon,
    cardStatusFeatureThreeDescription,
  ] = React.useMemo(() => items.map((item) => <DecorateEditorialHtml key={item.id} text={item.text} />), []);

  return (
    <div>
      <UitkLayoutGrid columns={12} space="six">
        <UitkSpacing padding={{ blockend: "two" }}>
          <UitkLayoutGridItem colSpan={4}>
            <div>
              <UitkText align="center">
                <div>{cardStatusFeatureOneIcon}</div>
              </UitkText>
              <UitkSpacing padding={{ blockstart: "one", blockend: "six" }}>
                <UitkText align="center" size={200} weight="medium">
                  <div>{cardStatusFeatureOneDescription}</div>
                </UitkText>
              </UitkSpacing>
            </div>
          </UitkLayoutGridItem>
        </UitkSpacing>

        <UitkLayoutGridItem colSpan={4}>
          <div>
            <UitkText align="center">
              <div>{cardStatusFeatureTwoIcon}</div>
            </UitkText>
            <UitkSpacing padding={{ blockstart: "one", blockend: "half" }}>
              <UitkText align="center" size={200} weight="medium">
                <div>{cardStatusFeatureTwoDescription}</div>
              </UitkText>
            </UitkSpacing>
          </div>
        </UitkLayoutGridItem>

        <UitkSpacing padding={{ blockend: "two" }}>
          <UitkLayoutGridItem colSpan={4}>
            <div>
              <UitkText align="center">
                <div>{cardStatusFeatureThreeIcon}</div>
              </UitkText>
              <UitkSpacing padding={{ blockstart: "one", blockend: "six" }}>
                <UitkText align="center" size={200} weight="medium">
                  <div>{cardStatusFeatureThreeDescription}</div>
                </UitkText>
              </UitkSpacing>
            </div>
          </UitkLayoutGridItem>
        </UitkSpacing>
      </UitkLayoutGrid>
    </div>
  );
};

export default CardComparisonFeatureSectionEditorial;

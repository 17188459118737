import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { UitkFigure, UitkFigureAspectRatioType } from "@egds/react-core/images";
import { Media } from "src/typings/microserviceModels/content-flex-module";
import { UitkGalleryCarousel } from "@egds/react-core/gallery-carousel";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { GalleryImage } from "src/components/shared/Gallery/typings";
import {
  Action,
  FlexTrackingInfo,
  sendImmediateClickstreamTrackEvent,
} from "src/components/utility/analytics/FlexAnalyticsUtils";
import { AnalyticsStore } from "bernie-plugin-mobx";
import { UitkToolbar } from "@egds/react-core/toolbar";
import { useLocalization } from "@shared-ui/localization-context";
import {
  UitkMediaGalleryImageDialog,
  UitkMediaGalleryImageView,
  UitkMediaGalleryThumbnailView,
} from "@egds/react-core/media-gallery";
import { UitkSheet, UitkSheetContent, UitkSheetTransition } from "@egds/react-core/sheet";
import { getFullScreenImages } from "src/components/utility/GalleryImageUtil";
import LazyLoad from "react-lazyload";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { UitkCardPlaceholder } from "@egds/react-core/cards";
import { UitkDialogAnimationType } from "@egds/react-core/dialog";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";

interface MediaCarouselProps {
  id: string;
  media: Media[];
  title: string;
  identifier?: string;
  analytics?: AnalyticsStore;
}

export const VideoDisplay = ({ id, video }: { id: string; video: Media }) => {
  const embedUrl = video.mediaUrl.split("watch?v=").join("embed/");

  return (
    <LazyLoad offset={500} height="100%" once placeholder={<UitkCardPlaceholder data-testid="editorial-video" />}>
      <UitkLayoutPosition type="relative" className="contentVideoContainer" tag="div">
        <UitkLayoutPosition type="absolute" position={{ top: "zero", left: "zero" }} cloneElement>
          <iframe
            id={`${id}-video`}
            title={video.mediaAlt}
            src={embedUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            height="100%"
            width="100%"
          />
        </UitkLayoutPosition>
      </UitkLayoutPosition>
    </LazyLoad>
  );
};

export const MediaCarousel = (props: MediaCarouselProps) => {
  const { id, media, title, identifier } = props;
  const convertedImages = media.reduce((acc: GalleryImage[], item) => {
    const { mediaUrl, mediaAlt, mediaCaption } = item;
    acc.push({
      alt: mediaAlt,
      src: mediaUrl,
      thumbnailSrc: mediaUrl,
      caption: mediaCaption,
    });

    return acc;
  }, []);

  const [currentCarouselIndex, setCurrentCarouselIndex] = React.useState(0);
  const [currentMediaGalleryIndex, setCurrentMediaGalleryIndex] = React.useState(0);
  /* istanbul ignore next */
  const mediaGalleryQuery = identifier ? `mediaGallery${identifier}` : "mediaGallery";
  /* istanbul ignore next */
  const thumbnailsGalleryQuery = identifier ? `thumbnailsGallery${identifier}` : "thumbnailsGallery";
  const galleryImages = getFullScreenImages(convertedImages);
  const { formatText } = useLocalization();
  const track = useClickTracker();
  const [isMediaDialogOpen, mediaDialogActions, MediaGalleryDialogComponent] = useDialog(mediaGalleryQuery);
  const [isThumbnailsDialogOpen, thumbnailsDialogActions, ThumbnailsGalleryDialogComponent] = useDialog(
    thumbnailsGalleryQuery
  );
  const triggerRef = React.createRef<HTMLElement>();

  const setCarouselIndex = (index: number) => {
    /* istanbul ignore next */
    const trackingInfo: FlexTrackingInfo = {
      moduleName: "GalleryCarousel",
      rfrr: "carousel.gallery-nav.btn",
      action: Action.CLICK,
      linkName: "Navigating carousel gallery viewer",
    };
    /* istanbul ignore next */
    sendImmediateClickstreamTrackEvent(trackingInfo, track);
    setCurrentCarouselIndex(index);
  };

  const openMediaGallery = (index?: number) => {
    const trackingInfo: FlexTrackingInfo = {
      moduleName: "GalleryCarousel",
      rfrr: "carousel.gallery.open",
      action: Action.CLICK,
      linkName: "RFRR Action Event",
    };
    sendImmediateClickstreamTrackEvent(trackingInfo, track);
    index === undefined ? setCurrentMediaGalleryIndex(currentCarouselIndex) : setCurrentMediaGalleryIndex(index);
    mediaDialogActions.openDialog();
  };

  const openThumbnailsGallery = () => {
    const trackingInfo: FlexTrackingInfo = {
      moduleName: "GalleryCarouselThumbnails",
      rfrr: "carousel.gallery.thumbnails.open",
      action: Action.CLICK,
      linkName: "RFRR Action Event",
    };
    sendImmediateClickstreamTrackEvent(trackingInfo, track);
    thumbnailsDialogActions.openDialog();
  };

  const closeMediaGallery = () => {
    const trackingInfo: FlexTrackingInfo = {
      moduleName: "GalleryCarousel",
      rfrr: "carousel.gallery.close",
      action: Action.CLICK,
      linkName: "RFRR Action Event",
    };
    sendImmediateClickstreamTrackEvent(trackingInfo, track);
    mediaDialogActions.closeDialog();
  };

  const closeThumbnailsGallery = () => {
    const trackingInfo: FlexTrackingInfo = {
      moduleName: "GalleryCarouselThumbnails",
      rfrr: "carousel.gallery.thumbnails.close",
      action: Action.CLICK,
      linkName: "RFRR Action Event",
    };
    sendImmediateClickstreamTrackEvent(trackingInfo, track);
    thumbnailsDialogActions.closeDialog();
    document.getElementById("galleryCarouselOpenButton")?.focus();
  };

  return (
    <div data-testid="galleryCarousel">
      <UitkGalleryCarousel
        images={convertedImages}
        carouselId={id}
        carouselName="Editorial Media Gallery Carousel"
        allyHeadingText="Media Gallery Carousel"
        currentIndex={currentCarouselIndex}
        onIndexChange={setCarouselIndex}
        onImageClick={openMediaGallery}
        onMediaButtonClick={openThumbnailsGallery}
        showGalleryButton={true}
      />
      <UitkSheetTransition isVisible={isMediaDialogOpen}>
        <MediaGalleryDialogComponent>
          <UitkMediaGalleryImageDialog
            animation={UitkDialogAnimationType.NONE}
            dialogShow={isMediaDialogOpen}
            strictFullscreen
            onDismiss={closeMediaGallery}
          >
            <UitkMediaGalleryImageView
              images={galleryImages}
              currentIndex={currentMediaGalleryIndex}
              onIndexChange={setCurrentMediaGalleryIndex}
            />
          </UitkMediaGalleryImageDialog>
        </MediaGalleryDialogComponent>
      </UitkSheetTransition>
      <UitkSheetTransition isVisible={isThumbnailsDialogOpen}>
        <ThumbnailsGalleryDialogComponent>
          <UitkSheet
            type="centered"
            isVisible={isThumbnailsDialogOpen}
            triggerRef={triggerRef}
            centeredSheetSize="small"
          >
            <UitkSheetContent>
              <UitkToolbar
                toolbarTitle={title}
                navigationContent={{
                  navIconLabel: formatText("mediaGallery.button.close"),
                  onClick: closeThumbnailsGallery,
                }}
              />
              <UitkMediaGalleryThumbnailView images={galleryImages} onImageSelected={openMediaGallery} />
            </UitkSheetContent>
          </UitkSheet>
        </ThumbnailsGalleryDialogComponent>
      </UitkSheetTransition>
    </div>
  );
};

interface MediaDisplayProps {
  id: string;
  media: Media[];
  title?: string;
}

export const MediaDisplay = ({ id, media, title }: MediaDisplayProps) => {
  if (media.length === 1) {
    const mediaItem = media[0];
    if (mediaItem.mediaType === "Image") {
      return (
        <UitkFigure
          ratio={UitkFigureAspectRatioType.R16_9}
          className="all-image-fit-cover"
          data-testid="galleryCarouselImage"
        >
          <BlossomImage src={mediaItem.mediaUrl} alt={mediaItem.mediaAlt} />
        </UitkFigure>
      );
    }
    return <VideoDisplay id={id} video={mediaItem} />;
  }
  return <MediaCarousel id={id} media={media} title={title || ""} identifier={id} />;
};

export default MediaDisplay;

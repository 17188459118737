import * as React from "react";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkText } from "@egds/react-core/text";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { UitkSpacing } from "@egds/react-core/spacing";

export const CitiContent = (props: { items: EditorialModel[] }) => {
  const { items } = props;
  const maxItemsAllowed = 2;

  if (items.length !== maxItemsAllowed) {
    return null;
  }

  return (
    <UitkLayoutFlex space="two" wrap="wrap" justifyContent="center">
      <UitkLayoutFlexItem maxWidth="one_hundred_twenty">
        <UitkLayoutGrid rows={1}>
          <UitkLayoutGridItem>
            <div>
              <UitkText align="left" size={200}>
                <UitkSpacing padding={{ blockstart: "twelve" }}>
                  <DecorateEditorialHtml text={items[0].text} />
                </UitkSpacing>
              </UitkText>
            </div>
          </UitkLayoutGridItem>

          <UitkLayoutGridItem>
            <div>
              <UitkText align="left" size={200}>
                <UitkSpacing padding={{ blockend: "twelve" }}>
                  <DecorateEditorialHtml text={items[1].text} />
                </UitkSpacing>
              </UitkText>
            </div>
          </UitkLayoutGridItem>
        </UitkLayoutGrid>
      </UitkLayoutFlexItem>
    </UitkLayoutFlex>
  );
};

export default CitiContent;

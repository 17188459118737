import * as React from "react";
import { UitkText } from "@egds/react-core/text";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { ViewExtraLarge, ViewLarge, ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { EditorialModel } from "src/typings/microserviceModels/content-flex-module";
import { UitkSpacing } from "@egds/react-core/spacing";

const CONTENT_ID_COUNT = 3;
export const CardComparisonMobileApplyNowEditorial = (props: { items: EditorialModel[] }) => {
  const { items } = props;

  if (items.length !== CONTENT_ID_COUNT) {
    return null;
  }

  const [horizontalLine, applyNowTitle, applyNowText] = React.useMemo(
    () => items.map((item) => <DecorateEditorialHtml key={item.id} text={item.text} />),
    []
  );

  return (
    <Viewport>
      <ViewExtraLarge />
      <ViewLarge />
      <ViewMedium>
        <div>
          <div>{horizontalLine}</div>
          <div>
            <UitkText size={400} weight="medium" align="center">
              <UitkSpacing padding={{ medium: { blockstart: "two" }, large: { blockstart: "unset" } }}>
                <div>{applyNowTitle}</div>
              </UitkSpacing>
            </UitkText>
            <UitkSpacing padding={{ medium: { blockstart: "one" }, large: { blockstart: "unset" } }}>
              <UitkText size={400} align="center">
                <div>{applyNowText}</div>
              </UitkText>
            </UitkSpacing>
          </div>
          <div>{horizontalLine}</div>
        </div>
      </ViewMedium>

      <ViewSmall>
        <div>
          <div>{horizontalLine}</div>
          <div>
            <UitkText size={400} weight="medium" align="center">
              <UitkSpacing padding={{ medium: { blockstart: "two" }, large: { blockstart: "unset" } }}>
                <div>{applyNowTitle}</div>
              </UitkSpacing>
            </UitkText>
            <UitkSpacing padding={{ small: { blockstart: "one" }, medium: { blockstart: "unset" } }}>
              <UitkText size={400} align="center">
                <div>{applyNowText}</div>
              </UitkText>
            </UitkSpacing>
          </div>
          <div>{horizontalLine}</div>
        </div>
      </ViewSmall>
    </Viewport>
  );
};

export default CardComparisonMobileApplyNowEditorial;

import * as React from "react";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText } from "@egds/react-core/text";
import { UitkMark } from "@egds/react-core/mark";

import EditorialBase from "../../EditorialBase";
import { ContentProps, EditorialFactoryProps } from "../../../typings";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";

export class ConfidenceMessageImageEditorial extends EditorialBase {
  public constructor(editorial: EditorialFactoryProps) {
    super(editorial);
  }

  public generate(props: ContentProps) {
    const { items } = this.editorial;
    const { media, text } = items[0];

    if (!media || media.length === 0 || !text) {
      return null;
    }

    return (
      <div
        id={this.id}
        data-fm={this.fmId}
        data-fm-title-id={this.fmTitleId}
        className="ConfidenceMessageImageEditorial"
        data-testid="confidence-message-image-editorial"
      >
        <UitkSpacing margin={{ block: "one" }}>
          <UitkLayoutFlex space="three" direction="row">
            <UitkLayoutFlexItem>
              <UitkMark url={media[0].mediaUrl} description={media[0].mediaAlt} size={12} lazyLoading="lazy" />
            </UitkLayoutFlexItem>
            <UitkLayoutFlexItem alignSelf="center" shrink={1}>
              <UitkText size={300}>
                <DecorateEditorialHtml text={text} />
              </UitkText>
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
        </UitkSpacing>
      </div>
    );
  }
}

export default ConfidenceMessageImageEditorial;

import { UitkHeading, UitkSubheading, UitkText } from "@egds/react-core/text";
import { UitkFigure } from "@egds/react-core/images";
import { UitkIcon } from "@egds/react-core/icons";
import * as React from "react";
import { DecorateEditorialHtml } from "src/components/flexComponents/Editorial/ContentUtilities";
import { uitkFigureAspectRatio } from "../editorials/EditorialWithLink";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { useEgClickstream, useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { ModuleViewType } from "components/flexComponents/Editorial/factory/EditorialContentFactory";
import { useRenderableContext } from "./DefaultEditorial";
import { UitkLink } from "@egds/react-core/link";
import { RenderableContentCardProps } from "./RenderableContentCard";
import { UitkCard, UitkCardLink } from "@egds/react-core/cards";
import { SpacingSize } from "src/components/utility/FlexAttributesUtil";

/**
 * Editorial On Canvas component that sits the content directly
 * in the DOM (without a card).
 *
 * IMPORTANT: As of May 17th 2021, we agreed on adding a workaround
 * placing <UitkCard/> as its comp's parent element to support
 * rounded corner image using existing Uitk classes, and <UitkCardLink/>
 * to support the whole card clickable. All this to not adding more custom
 * CSS to blossom's layout. This is just TEMPORARY and it is not going to be
 * permanent, this was already discussed with EGDS and we are going to contribute
 * to UITK for round corners in images once it is in EGDS.
 * More info in: https://expedia.slack.com/archives/C88UW0PU7/p1621006460220000
 *
 *
 */

interface CollapsableContentProps extends Pick<RenderableContentCardProps, "toggleStatus" | "flipToggleStatus"> {}
interface ContentSectionProps extends Pick<RenderableContentCardProps, "displayEditorialClassNames"> {
  isExpandoEnabled?: boolean | string;
}
interface RenderableContentOnCanvasProps extends RenderableContentCardProps {}

export const RenderableContentOnCanvas = ({
  isExpandoEnabled,
  toggleStatus,
  flipToggleStatus,
  displayEditorialClassNames,
}: RenderableContentOnCanvasProps) => {
  const { title, context, key, media } = useRenderableContext();
  const track = useEgClickstream();
  const mediaUrl = media?.clickUrl;
  const mediaCaption = media?.mediaCaption;
  const refId = media?.refId;

  const sendSelectedEvent = () => {
    if (refId) {
      const [eventName, action_location] = refId.split("/");

      track({
        event: {
          event_version: "1.0.0",
          event_name: `${eventName}.selected`,
          event_type: "Interaction",
          action_location,
        },
      });
    }
  };

  return (
    <LazyLoad context={context}>
      <UitkCard onClick={sendSelectedEvent} className="noBackground cardWithLinkText">
        <UitkCardLink>
          <a href={mediaUrl}>{mediaCaption || title}</a>
        </UitkCardLink>
        <UitkLayoutGrid key={key}>
          {media?.mediaUrl && <MediaContent />}
          {isExpandoEnabled && <CollapsableContent toggleStatus={toggleStatus} flipToggleStatus={flipToggleStatus} />}
          <div
            data-testid="editorial-content"
            className={`editorialContent ${isExpandoEnabled && toggleStatus ? "is-visually-hidden" : ""}`}
          >
            <ContentSection
              isExpandoEnabled={isExpandoEnabled}
              displayEditorialClassNames={displayEditorialClassNames}
            />
          </div>
        </UitkLayoutGrid>
      </UitkCard>
    </LazyLoad>
  );
};

const CollapsableContent: React.FC<CollapsableContentProps> = ({ toggleStatus, flipToggleStatus }) => {
  const { title, subtitle } = useRenderableContext();

  const expandoKeyboardPress = (e: React.KeyboardEvent) => {
    //button elements should allow click on enter or space when a keydown event occurs. Allow by default when a mouse event.
    if (e.key === "Enter" || e.key === " ") {
      flipToggleStatus();
    }
  };
  return (
    <div
      tabIndex={0}
      role="button"
      aria-expanded={!toggleStatus}
      className="header"
      onClick={flipToggleStatus}
      onKeyDown={expandoKeyboardPress}
    >
      <UitkLayoutGrid columns={2}>
        <UitkLayoutGridItem colSpan={1}>
          <UitkHeading tag="h2" size={4}>
            {title}
          </UitkHeading>
        </UitkLayoutGridItem>
        <UitkLayoutGridItem colSpan={1}>
          <UitkLayoutFlex justifyContent="end">
            <UitkLayoutFlexItem>
              <div>
                <UitkSpacing margin={{ blockstart: "six" }}>
                  <UitkSubheading tag="h3">{subtitle}</UitkSubheading>
                </UitkSpacing>
              </div>
            </UitkLayoutFlexItem>
            <UitkLayoutFlexItem>
              <UitkIcon name={`expand_${toggleStatus ? "more" : "less"}`} />
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
        </UitkLayoutGridItem>
      </UitkLayoutGrid>
    </div>
  );
};

const ContentSection: React.FC<ContentSectionProps> = ({ isExpandoEnabled, displayEditorialClassNames }) => {
  const { title, subtitle, text, media, view, trackRfrridClick, padding, id } = useRenderableContext();

  const mediaUrl = media?.clickUrl;
  const mediaCaption = media?.mediaCaption;
  const withPadding = padding !== SpacingSize.NONE;

  const isSingleColumn = view === ModuleViewType.SingleColumnOnCanvas || ModuleViewType.OnCanvasBanner;
  const isOnCanvasBanner = view === ModuleViewType.OnCanvasBanner;

  const showClickableMedia = mediaUrl && mediaCaption && !isOnCanvasBanner;

  const contentHeading = !isOnCanvasBanner ? (
    <UitkHeading tag="h2" size={4}>
      {title}
    </UitkHeading>
  ) : (
    <UitkText size={200}>{title}</UitkText>
  );
  const contentSubtitle = !isOnCanvasBanner ? (
    <UitkSubheading>{subtitle}</UitkSubheading>
  ) : (
    <UitkHeading tag="h2" size={6}>
      {subtitle}
    </UitkHeading>
  );

  const textClassNames = isSingleColumn ? "singleColumn" : "editorialTextColumn";

  return (
    <UitkSpacing padding={withPadding ? { block: "three" } : "unset"}>
      <UitkLayoutGridItem>
        <div className={displayEditorialClassNames} data-fm-content={id} id={id}>
          {title && !isExpandoEnabled && (
            <UitkSpacing padding={isOnCanvasBanner ? { blockend: "one" } : "unset"}>{contentHeading}</UitkSpacing>
          )}
          {subtitle && !isExpandoEnabled && contentSubtitle}
          {text && (
            <UitkSpacing padding={withPadding ? { blockstart: "two" } : "unset"}>
              <UitkText size={300} weight="regular">
                <div onClick={trackRfrridClick && trackRfrridClick(useClickTracker())} className={textClassNames}>
                  <DecorateEditorialHtml text={text} />
                </div>
              </UitkText>
            </UitkSpacing>
          )}
          {showClickableMedia && (
            <UitkSpacing margin={{ blockstart: "four" }} padding="unset">
              <UitkLink aria-hidden="true">
                <a href={mediaUrl} data-testid="editorial-media-caption-link">
                  {mediaCaption}
                </a>
              </UitkLink>
            </UitkSpacing>
          )}
        </div>
      </UitkLayoutGridItem>
    </UitkSpacing>
  );
};

const MediaContent: React.FC = () => {
  const { media, imageAspectRatio = "16-9" } = useRenderableContext();

  return (
    <UitkLayoutGridItem>
      <div data-testid="image-media-content" aria-hidden="true">
        <UitkFigure className="uitk-card-roundcorner-all" ratio={uitkFigureAspectRatio(imageAspectRatio)}>
          <BlossomImage src={media?.mediaUrl || ""} alt={media?.mediaAlt || ""} imageRatio={imageAspectRatio} noCrop />
        </UitkFigure>
      </div>
    </UitkLayoutGridItem>
  );
};
